/**
 * 二维码状态
 * 0-未扫码 1=已扫码，2- 制作完成
 */
export const QRCODE_STATUS = {
  /**
   * 0-未扫码
   */
  NORMAL: 0,
  /**
   * 1-已扫码
   */
  SCANNED: 1,
  /**
   * 2-签名制作完成
   */
  MAKE_SIGN_NAME_SUCCESS: 2,
}
